:root {
  --white: #fafafa;
  --black: #18181a;
  --black1: #000000;
  --blue: #0c63ca;
  --lightBlue: #d1e6fa;
  --gray: #e9ebef;
  --gray1: #767680;
  --gray2: #f2f4f8;
  --border-color: #bbbcc2;
  --border-color2: #1d5597;
  --baseFont: 16px;
  --fontFamily: 'Avenir Next';
  --fontFamily1: 'Avenir Next Demi';
  --errorColor: #d10909;
  --lightRed: #fdecec;
  --green: #1d890b;
}
:focus-visible {
  outline-style: ridge;
}
html {
  scroll-behavior: smooth;
}
body {
  overflow: auto;
  font-family: Arial, sans-serif;
}
* {
  box-sizing: border-box;
}
body a,
body a:hover {
  text-decoration: none;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.color-black {
  color: var(--black) !important;
}
.color-black1 {
  color: var(--black1) !important;
}
.color-blue {
  color: var(--blue) !important;
}
.color-white {
  color: var(--white) !important;
}
.bg-gray {
  background-color: var(--gray) !important;
}
.bg-white {
  background-color: var(--white) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fw-normal {
  font-family: 'Avenir Next' !important;
  font-weight: normal !important;
}
.fw-medium {
  font-family: 'Avenir Next' !important;
  font-weight: 500 !important;
}
.fw-semibold {
  font-family: 'Avenir Next Demi' !important;
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: bold !important;
}
html {
  scrollbar-width: thin;
}
.cursor-pointer {
  cursor: pointer !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.position-relative {
  position: relative !important;
}
.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.w-100 {
  width: 100% !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.m-0 {
  margin: 0px !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 8px !important;
}
.rounded-100 {
  border-radius: 50% !important;
}
.boxShadow1 {
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 6px 6px 0px rgba(0, 0, 0, 0.04),
    0px 3px 6px 0px rgba(0, 0, 0, 0.04) !important;
}
.boxShadow3 {
  box-shadow:
    0px 10px 20px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px 0px rgba(0, 0, 0, 0.12),
    0px 10px 20px 0px rgba(0, 0, 0, 0.08) !important;
}
.mb-1 {
  margin-bottom: 8px !important;
}
.ml-1 {
  margin-left: 8px !important;
}
.space-nowrap {
  white-space: nowrap !important;
}
.text-danger {
  color: var(--errorColor) !important;
}
.background-secondary {
  background: var(--gray1) !important;
}
div#root {
  height: 100%;
}
.authentication-form-wrap {
  margin-top: 70px;
}
.form {
  max-width: 470px;
  padding: 32px;
  border: 1px solid var(--border-color);
}
.form .form-title {
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 35px;
}
.form.registerForm .form-title {
  margin-bottom: 30px;
}
.req {
  color: var(--errorColor);
}
.Mui-error.MuiInputBase-formControl {
  background-color: var(--lightRed) !important;
}
.Mui-error.MuiInputBase-formControl fieldset,
.text-error {
  border-color: var(--errorColor) !important;
}
.Mui-error.MuiInputBase-formControl .MuiIconButton-root.gray-icon-btn {
  background-color: var(--lightRed) !important;
  color: var(--gray1) !important;
}
.Mui-error.MuiInputBase-formControl
  .MuiIconButton-root.gray-icon-btn:hover
  svg
  path {
  fill: var(--gray1) !important;
}
.MuiFormHelperText-root.Mui-error:before,
.text-error:before {
  content: '';
  background: url('../img/errorIcon.svg') no-repeat scroll center transparent;
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  background-size: 16px auto;
}
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 18% auto; /* Adjust margin to center loader vertically */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* Landing page css Start */
.landing-page-container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}
.container-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.goToId:before {
  content: '';
  height: 68px;
  display: block;
  margin-top: -68px;
}
.landing-page p.pera-text {
  line-height: 24px;
  margin-bottom: 15px;
}
p.price-benefit {
  margin-bottom: 3px;
}
.landing-page .price-btn {
  padding: 2.5px;
  min-width: 84px;
  margin-top: 24px;
}
.service-icon {
  background: #f2f4f8;
  height: 89px;
  width: 89px;
  margin-bottom: 20px;
}
.landing-page header,
.header-logo-img,
.header-logo-img img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.swiper.swiper-horizontal .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  box-shadow: none;
  background: #bbbcc2;
  margin: 0 6px;
}
.swiper.swiper-horizontal
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper.swiper-horizontal .swiper-pagination .swiper-pagination-bullet:hover {
  background: var(--white);
}
.swiper .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 7px);
}
.swiper .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 7px);
}
.swiper .swiper-button-prev:after,
.swiper .swiper-button-next:after {
  height: 47px;
  width: 27px;
  background: url('../img/sliderArrow.svg') no-repeat scroll 8px 14px
    transparent !important;
  opacity: 1;
  font-size: 0px;
}
.swiper .swiper-button-prev:hover:after,
.swiper .swiper-button-next:hover:after {
  opacity: 0.4;
}
.swiper-button-next:after {
  transform: rotateZ(175deg);
  top: -3px;
  position: relative;
}
.slider-image-wrap {
  padding-top: 20px;
  padding-bottom: 50px;
}
.slider-box {
  box-shadow: 0 48px 80px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
}
.landing-page .slider-image-wrap img {
  object-fit: cover;
  box-shadow:
    0px 48px 80px 0px rgba(0, 0, 0, 0.24),
    0px 32px 48px 0px rgba(0, 0, 0, 0.16),
    0px 12px 24px 0px rgba(0, 0, 0, 0.08) !important;
  border-radius: 10px;
  height: auto;
  width: auto;
}
.landing-page .fs-40 {
  line-height: 44px !important;
}
.headerMenuDrawer .MuiPaper-root {
  border-radius: 0px;
  width: 280px;
  padding: 10px;
}
.headerMenuDrawer .MuiPaper-root .menuCloseBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.headerMenuDrawer .MuiPaper-root .header-menu {
  margin-top: 60px;
}
.headerMenuDrawer .MuiPaper-root .header-menu li.MuiListItem-root {
  margin-bottom: 10px;
}
p.developer-1 {
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 99;
}
p.developer-2 {
  position: absolute;
  bottom: 0;
  background: rgba(24, 24, 26, 0.3);
  padding: 0px 19px;
  right: 140px;
  z-index: 99;
}
.contact-checkbox .MuiTypography-root {
  font-size: 18px;
  margin-left: 3px;
}
.interest-field .MuiRadio-root svg.MuiSvgIcon-root {
  font-size: 22px;
}
.interest-field .MuiFormControlLabel-label {
  font-size: 18px;
}
.landing-page .headerSticky.header {
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.5s ease;
}
.landing-page .header {
  z-index: 999;
  padding-top: 23.5px;
  padding-bottom: 23.5px;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  width: 100%;
}
.landing-page .main-content {
  margin-top: 120px;
}
.service-slider-block .swiper {
  max-width: 856px;
}
.landing-page .contact-checkbox {
  margin-left: -6px;
  align-items: flex-start;
}
.landing-page .contact-checkbox .MuiCheckbox-root {
  padding: 0px 4px;
}
.autocomplete-address {
  position: 'absolute';
  width: 100%;
  background-color: var(--white);
  padding: 0px;
  border-radius: 8px;
  left: 0;
  top: 100%;
  bottom: auto;
  border: 1px solid var(--border-color);
  z-index: 1;
  height: fit-content;
  overflow-y: hidden;
}
.autocomplete-address-wrap {
  height: fit-content;
  overflow-y: auto;
  padding: 0 8px;
}

@media (max-width: 600px) {
  .autocomplete-address-wrap {
    overflow-y: hidden;
  }
  .css-v6gz8q-MuiTypography-root {
    font-size: 14px !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-background-color: #ffffff !important;
}
ul.home-service-items {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.home-service-items li {
  margin: 0 0 10px;
}
.home-service-slider .swiper {
  padding: 0 0 22px;
}
.home-service-slider .swiper-pagination {
  bottom: -5px !important;
}
.home-service-slider
  .swiper.swiper-horizontal
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active,
.home-service-slider
  .swiper.swiper-horizontal
  .swiper-pagination
  .swiper-pagination-bullet:hover {
  background: var(--blue);
}
.job-image-wrap > svg,
.about-image-wrap svg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.topBannerSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 1020px;
}
/* Landing page css End */

.grecaptcha-badge {
  z-index: 1;
}
.section-title > svg {
  min-width: 42px;
  height: auto;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home-service-text-content {
    padding-right: 10px !important;
  }
  .home-service-text-content > div {
    padding: 10px 0 0;
  }
  .home-service-text-content .section-title {
    margin-bottom: 20px;
  }
  ul.home-service-items li {
    line-height: 22px;
  }
  .job-text-content {
    padding-right: 15px !important;
  }
  .landing-page p.pera-text {
    margin-bottom: 8px;
  }
  .job-text-content .section-title {
    margin-bottom: 10px;
  }
  .job-text-content p.MuiTypography-root.fs-18 {
    font-size: 16px !important;
  }
  .job-text-content > div {
    padding: 10px 0;
  }
  .job-text-content .MuiButtonBase-root {
    margin-top: 15px;
  }
  #aboutUsSection p.MuiTypography-root.fs-18.pera-text {
    font-size: 16px !important;
    line-height: 22px;
  }
  #aboutUsSection .section-title {
    margin-bottom: 15px;
  }
}
@media (min-width: 1200px) {
  .justify-lg-content-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 900px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-none {
    display: none !important;
  }
}
@media (min-width: 600px) {
  .d-sm-flex {
    display: flex !important;
  }
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .align-sm-items-center {
    align-items: center !important;
  }
}

@media (max-width: 1024px) {
  .landing-page .fs-40 {
    line-height: 40px !important;
    font-size: 35px !important;
  }
  .landing-page p.pera-text {
    line-height: 22px;
    margin-bottom: 10px;
    font-size: 16px !important;
  }
}
@media (max-width: 899px) {
  .authentication-form-wrap {
    margin-top: 24px;
  }
  .header .MuiButton-text {
    min-width: auto;
    font-size: 0px;
    background: transparent !important;
  }
  .header .MuiButton-text > svg {
    font-size: 34px;
    color: var(--black);
  }
  .fs-40 {
    font-size: 32px !important;
  }
  .fs-24 {
    font-size: 20px !important;
  }
  .fs-18 {
    font-size: 16px !important;
  }
  .fs-16 {
    font-size: 15px !important;
  }
  .form .form-title {
    margin-bottom: 16px;
  }
  .section-title > svg {
    min-width: 32px;
    width: 32px;
  }
  .landing-page .fs-40 {
    line-height: 28px !important;
    font-size: 24px !important;
  }
  .landing-page .fs-28 {
    font-size: 22px !important;
  }
  .landing-page .fs-24 {
    font-size: 18px !important;
  }
  .landing-page .header .header-logo-img {
    width: 150px;
  }
  .landing-page .header,
  .landing-page .headerSticky.header {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .landing-page .main-content {
    margin-top: 52px;
  }
  .goToId:before {
    height: 51px;
    margin-top: -51px;
  }
}
@media (max-width: 767px) {
  .form {
    padding: 16px;
  }
  .service-icon {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
  .interest-field .MuiFormControlLabel-label,
  .contact-checkbox .MuiTypography-root {
    font-size: 16px;
  }
}
@media (max-width: 599px) {
  .fs-40 {
    font-size: 24px !important;
  }
  .fs-28 {
    font-size: 20px !important;
  }
  .fs-24 {
    font-size: 18px !important;
  }
  .fs-18 {
    font-size: 14px !important;
  }
  .fs-16 {
    font-size: 13px !important;
  }
  p.developer-2 {
    right: 70px;
  }
  p.developer-1 {
    top: 4px;
    right: 10px;
  }
  /* #serviceSection .section-title > p,
  .faq-section .section-title > p {
    max-width: 240px;
  } */
  .section-title > p {
    padding-top: 0px;
    margin-top: -2px;
  }
  #aboutUsSection .section-title > p,
  .section-title.contact-section-title > p {
    padding-top: 2px;
    margin-top: 0px;
  }
  .interest-field .MuiFormControlLabel-label,
  .contact-checkbox .MuiTypography-root {
    font-size: 14px;
  }
  .common-accordion-box > .MuiPaper-root .MuiAccordionSummary-root {
    min-height: auto;
    padding: 16px 15px;
  }
  .landing-page .contact-checkbox .MuiCheckbox-root {
    margin: 0px 0;
  }
  ul.home-service-items li {
    font-size: 16px;
  }
}
